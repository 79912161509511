<template>
  <h1>Заявка на подключение самозанятости</h1>
  <h3>Данные банковского счета</h3>
  <form @submit.prevent class="uk-form-stacked">
    <div class="uk-margin">
      <div class="uk-alert-primary" uk-alert>
        <p>Если вы являетесь клиентом банка Тинькофф, то вам будет отправлено приглашение в приложении.</p>
      </div>
      <label class="uk-form-label">Счет</label>
      <select class="uk-select" v-model="formValues.accountType">
        <option value="another">Счет в другом банке</option>
        <option value="tinkoff_old">Счет в Тинькофф</option>
        <option value="tinkoff_new">Получить новую карту Тинькофф</option>
      </select>
      <div class="" v-if="formErrors.accountType">
        <span class="uk-text-danger" v-for="error in formErrors.accountType">{{ error }}</span>
      </div>
    </div>
    <div v-if="formValues.accountType == 'another'">
      <div class="uk-margin">
        <label class="uk-form-label">БИК</label>
        <input class="uk-input" type="text" placeholder="044525974" v-model="formValues.bik">
        <div class="" v-if="formErrors.bik">
          <span class="uk-text-danger" v-for="error in formErrors.bik">{{ error }}</span>
        </div>
      </div>
    </div>
    <div v-if="formValues.accountType != 'tinkoff_new'">
      <div class="uk-margin">
        <label class="uk-form-label">Номер счета</label>
        <input class="uk-input" type="text" placeholder="30232810100000000004" v-model="formValues.accountNumber">
        <div class="" v-if="formErrors.accountNumber">
          <span class="uk-text-danger" v-for="error in formErrors.accountNumber">{{ error }}</span>
        </div>
      </div>
    </div>

    <button class="uk-button uk-button-primary" @click="submitForm">Сохранить</button>
    <!--    <button class="uk-button uk-button-default" @click="turnBack">Назад</button>-->
  </form>
</template>

<script lang="ts">
import {onMounted, ref} from "vue";
// @ts-ignore
import BankFormService, {StoreResponse} from "@/services/employee/BankFormService";
// @ts-ignore
import EmployeeService, {Stage, StageResponse} from "@/services/employee/EmployeeServise";
// @ts-ignore
import router from "@/router";

export default {
  setup() {
    const formValues = ref({
      accountType: "another",
      bik: "",
      accountNumber: "",
    })
    const formErrors = ref({})

    async function checkStage() {
      const response = await EmployeeService.stage();
      const data = response.data.data as StageResponse

      if (data.next != Stage.Bank) {
        await router.push({name: `employee/${data.next}`})
      }
    }

    async function submitForm() {
      try {
        const response = await BankFormService.store(formValues.value);
        await router.push({name: `employee/${response.data.data.next}`})
      } catch (err) {
        const data = err.response.data as StoreResponse;
        if (data.errors) {
          formErrors.value = data.errors;
        }
      }
    }

    async function turnBack() {
      const response = await BankFormService.back();
      await router.push({name: `employee/${response.data.data.next}`})
    }

    onMounted(async () => {
      await checkStage();
    })

    return {
      formValues,
      formErrors,
      submitForm,
    }
  }
}
</script>